import React from "react";
import styles from "./Purchase.module.css";
import BlocketButton from "../BlocketButton/BlocketButton";
import { BlocketInsuranceClient, InsuranceBlocketReq } from "../../BackendApi/OmocomStoreApi";
import { DeploymentEnvironment } from "../../BackendApi/DeploymentEnvironment";
import { OmocomStoreApiClientConfig } from "../../BackendApi/OmocomStoreApiClientConfig";
import { FormValidation } from "../../Utils/validation/validation";

interface PurchaseProps {
  selectedPackage: number,
  email: string
}

function Purchase(props: PurchaseProps) {
  const [loading, setLoading] = React.useState(false);

  const purchase = async () => {
    if (!FormValidation().isValidEmail(props.email)) {
      let userEmail = document.getElementById('userEmail');
      if (userEmail) {
        userEmail.style.border = '2px solid red';
        userEmail.focus();
      }
      return;
    }

    setLoading(true);
    const deploymentEnv = new DeploymentEnvironment();
    const authClientConfig = new OmocomStoreApiClientConfig(deploymentEnv.apiUrl, undefined);
    const insuranceClient = new BlocketInsuranceClient(authClientConfig);

    const queryString = require('query-string');

    const parsed = queryString.parse(window.location.search);

    const payload = {
      adTitle: parsed.adTitle,
      listId: parsed.listId,
      salePrice: { amount: Number.isInteger(parseInt(parsed.salePrice)) ? parsed.salePrice : 0, currency: 'SEK' },
      adPrice: { amount: Number.isInteger(parseInt(parsed.adPrice)) ? parsed.adPrice : 0, currency: 'SEK' },
      insuredAmount: { amount: props.selectedPackage, currency: 'SEK' },
      isBlocketPaket: parsed.blocketPackage && parsed.blocketPackage !== "false" ? true : false,
      userCultureInfo: 'sv-SE',
	    fullUrl: window.location.href,
      emailAddress: props.email,
	    adText: parsed.adText,
      extraImages: parsed.extraImages
    } as InsuranceBlocketReq

    sessionStorage.setItem("mail", props.email);
    sessionStorage.setItem("referral", parsed.referral ?? '');
    const response = await insuranceClient.purchaseInsurance("blocket", "se", payload);
    response.paymentUrl && (window.location.href = response.paymentUrl);
    setLoading(false);
  }

  return (
    <div className={styles.purchaseWrapper}>
      <BlocketButton value="Betala med Trustly" loading={loading} onClick={() => purchase()} />
    </div>
  )
}

export default Purchase;
