import React, { useEffect, useState } from "react";
import styles from "./Blocket.module.css";
import "animate.css";
import Footer from "./Footer/Footer";
import Title from "./Title/Title";
import TitleImg from "./TitleImg/TitleImg";
import { SoldItemName, CompensationList, ExternalLinks, Disclaimer } from "./Information/Information";
import Email from "./Email/Email";
import SelectPackage from "./Package/Package";
import Purchase from "./Purchase/Purchase";
import BlocketHeader from "./BlocketHeader/BlocketHeader";
import PackagesArray from "./Package/PackageAPI";

function getDefaultPackage(packages: any) {
  return packages[packages.length - 1].upTo.amount;
}

function getDefaultEmail() {
  const queryString = require('query-string');
  const parsed = queryString.parse(window.location.search);

  return parsed.emailAddress ?? '';
}

function Blocket() {
  const [selectedPackage, setSelectedPackage] = useState<number>(0);
  const [packages, setPackages] = useState<any>([]);
  const [isPhone, setIsPhone] = useState<boolean>(false);
  const [email, setEmail] = useState<string>((getDefaultEmail));

  useEffect(() => {
    const setAvailablePackages = async () => {
      let payload = await PackagesArray();
      let availablePackages = payload.priceList;
      let defaultSelected = getDefaultPackage(availablePackages);
      setSelectedPackage(defaultSelected);
      setPackages(availablePackages);
    }

    const updateWindowDimensions = () => {
      if (window.innerWidth < 800) {
        setIsPhone(true);
      }
      else {
        setIsPhone(false);
      }
    };

    setAvailablePackages();

    window.addEventListener("resize", updateWindowDimensions);
    updateWindowDimensions();
    return () => window.removeEventListener("resize", updateWindowDimensions)
  }, []);

  return (
    <div className={styles.blocketWrapper}>
      <div className={styles.blocketInnerWrapper}>
        <BlocketHeader />
        {!isPhone ?
          <div className={styles.blocket}>
            <Title />
            <div className={styles.blocketInner}>
              <TitleImg />
              <div className={styles.inlineRightWrapper}>
                <SoldItemName />
                <CompensationList />
				<Disclaimer />
              </div>
              <div>
                <SelectPackage packages={packages} selectedPackage={selectedPackage} setSelectedPackage={(item: number) => setSelectedPackage(item)} />
                <div className={styles.inline}>
                  <Email email={email} setEmail={(value: string) => setEmail(value)} />
                  <Purchase selectedPackage={selectedPackage} email={email} />
                </div>
                <ExternalLinks />
              </div>
            </div>
            <Footer />
          </div>
          : <div className={styles.blocket}>
              <Title />
              <div className={styles.blocketInlineTitle}>
                <TitleImg />
                <SoldItemName />
              </div>
              <CompensationList />
			  <Disclaimer />
              <SelectPackage packages={packages} selectedPackage={selectedPackage} setSelectedPackage={(item: number) => setSelectedPackage(item)} />
              <Email email={email} setEmail={(value: string) => setEmail(value)} />
              <Purchase selectedPackage={selectedPackage} email={email} />
              <ExternalLinks />
              <Footer />
          </div>
        }
      </div>
    </div>
  );
}

export default Blocket;
