import React from "react";
import styles from "./Email.module.css";

interface IProps {
    email: string;
    setEmail: Function;
}

export default function InsuranceText(props: IProps) {
    return (
        <input type='email' id='userEmail' className={styles.email} placeholder='E-postadress' value={props.email} onChange={(e) => { props.setEmail(e.currentTarget.value) }} />
    )
}