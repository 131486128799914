import React, { memo, useState } from "react";
import StoreForm from "./StoreForm";
import StoreHeader from "./StoreHeader";
import { IntlProvider } from "react-intl";
import { localeLanguage, messagesForLanguages } from "./translations/locales";

const StoreContainer = () => {
	const [locale, setLocale] = useState(localeLanguage);
	const messages = { ...messagesForLanguages.en, ...messagesForLanguages[locale] };

	return (
		<div id="maincontainer">
			<div id="formholder" className="store-container">
				<IntlProvider key={locale} locale={locale} messages={messages}>
					<StoreHeader setLocale={() => {setLocale(locale === "sv" ? "en" : "sv")}}/>
					<StoreForm />
				</IntlProvider>
			</div>
		</div>
	);
};

export default memo(StoreContainer);
