import { DeploymentEnvironment } from "../../BackendApi/DeploymentEnvironment";
import { InsuranceInfoClient} from "../../BackendApi/OmocomStoreApi";
import { OmocomStoreApiClientConfig } from "../../BackendApi/OmocomStoreApiClientConfig";

export default async function PackagesArray() {
	const deploymentEnv = new DeploymentEnvironment();	
    const insuranceInfoClient = new OmocomStoreApiClientConfig(deploymentEnv.apiUrl);
    const insuranceClient = new InsuranceInfoClient(insuranceInfoClient);

    const response = await insuranceClient.getPriceList("blocket", "se");

    return response;
}