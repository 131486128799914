import React from "react";
import styles from "./Package.module.css";
import checkMark from "../../Utils/Images/check_white.svg";
import { PriceListItem } from "../../BackendApi/OmocomStoreApi";

interface SelectPackageProps {
    packages: any;
    selectedPackage: number;
    setSelectedPackage: Function;
}

interface PackageProps {
    defaultSelected: number,
    item: PriceListItem,
    setPackage: Function,
}

function SelectPackage(props: SelectPackageProps) {
    const setAvailablePackages = () => {
        var packages = [] as any;
        props.packages && props.packages.forEach((item: PriceListItem) => {
            packages.push(
                <Package key={item.name} item={item} defaultSelected={props.selectedPackage} setPackage={props.setSelectedPackage} />
            )
        });
        return packages;
    }

    return (
        <div className={styles.packagesWrapper}>
            <div className={styles.packagesHeader}>
                Välj försäkringspaket
            </div>
            {setAvailablePackages()}
        </div>
    )
}

function Package(props: PackageProps) {
    return (
        <div className={styles.package} onChange={e => props.setPackage(parseInt((e.target as HTMLInputElement).value))}>
            <input type="radio" defaultChecked={props.item.upTo && props.defaultSelected === props.item.upTo.amount} id={props.item.name} name="packages" value={props.item.upTo && props.item.upTo.amount} />
            <label className={styles.packageLabel} htmlFor={props.item.name}>
                <div className={styles.packageContent}>
                    <div className={styles.packageTitle}>
                        {props.item.name}
                    </div>
                    <div className={styles.packagePrice}>
                        {props.item.premium && props.item.premium.amount}kr
                </div>
                    <div className={`${styles.packageText}`}>
                        Ersätter dolda fel upp till maximalt {props.item.upTo && props.item.upTo.amount} kr
                </div>
                </div>
                <span className={styles.packageButtonWrapper}>
                    <span className={`${styles.packageButton} ${styles.unselected}`}>VÄLJ</span>
                    <img className={`${styles.packageButton} ${styles.selected}`} alt={'✓'} src={checkMark} />
                </span>
            </label>
        </div>
    )
}

export default SelectPackage;