import React, { useState } from "react";
import "../Velosophy.css"
import { DeploymentEnvironment } from "../../BackendApi/DeploymentEnvironment";
import { OmocomStoreApiClientConfig } from "../../BackendApi/OmocomStoreApiClientConfig";
import { InsuranceClient, InsuranceStoreReq } from "../../BackendApi/OmocomStoreApi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Fields(props: { isPhone: boolean; }) {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [model, setModel] = useState("");
    const [date, setDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const setMinDate = () => {
        let date = new Date();
        date.setDate(date.getDate() - 14);
        return date;
    }

    const purchase = async () => {
        let nameField = document.getElementById("fieldsWrapper");
        nameField && nameField.classList.add('submit');

        if (!name || !surname || !email || !model || !date) {
            return;
        }

        let insuranceStart = new Date();
        insuranceStart.setHours(insuranceStart.getHours() + 1);
        let insuranceEnd = new Date();
        insuranceEnd.setFullYear(insuranceEnd.getFullYear() + 1);
        insuranceEnd.setHours(insuranceEnd.getHours() + 1);

        setLoading(true);
        const deploymentEnv = new DeploymentEnvironment();
        const authClientConfig = new OmocomStoreApiClientConfig(deploymentEnv.apiUrl, 'unused');
        const insuranceClient = new InsuranceClient(authClientConfig);

        const payload = {
            firstName: name,
            lastName: surname,
            emailAddress: email,
            userCultureInfo: navigator.language,
            insuranceStart: insuranceStart,
            insuranceEnd: insuranceEnd,
            purchasedProducts: [
                {
                    productName: "Deductible",
                    omocomCounterpartHandle: "c49a1cfb-d809-459a-8774-b78765d24a3f",
                    shortDescription: model,
                    objectId: model,
                    insurableObjectExternalProperties: [
                        {
                            name: "Model",
                            description: "Velosophy model",
                            value: model
                        },
                        {
                            name: "Purchase date",
                            description: "Velosophy purchase date",
                            dateTimeValue: date
                        },
                    ],
                },
            ]
        } as InsuranceStoreReq

        sessionStorage.setItem("veloMail", email);
        const response = await insuranceClient.purchase("velosophy", "se", payload);
        response.paymentUrl && (window.location.href = response.paymentUrl);
        setLoading(false);
    }

    return (
        <div id="fieldsWrapper" className="fields-wrapper">
            {!props.isPhone ?
                <>
                    <div className="row">
                        <input id="name" className={name ? "input" : "input red"} type="text" placeholder="Förnamn"
                            value={name} onChange={(item) => setName(item.currentTarget.value)} />
                        <input id="surname" className={surname ? "input" : "input red"} type="text" placeholder="Efternamn"
                            value={surname} onChange={(item) => setSurname(item.currentTarget.value)} />
                        <input id="email" className={email ? "input" : "input red"} type="text" placeholder="E-postadress"
                            value={email} onChange={(item) => setEmail(item.currentTarget.value)} />
                    </div>
                    <div className="row">
                        <div id="radioButtons" className={model ? "radio-buttons" : "radio-buttons red"}>
                            <div className="radio-text">
                                Välj din Vélosophy-modell:
                            </div>
                            <div className="radio-buttons-inner">
                                <input style={{ display: 'none' }} type="radio" id="Comfort" name="model"
                                    value="Comfort" onChange={(item) => setModel(item.currentTarget.value)} />
                                <label htmlFor={"Comfort"} className={model === "Comfort" ? "fields-radio checked" : 'fields-radio'}>Comfort</label>
                                <input style={{ display: 'none' }} type="radio" id="Sport" name="model"
                                    value="Sport" onChange={(item) => setModel(item.currentTarget.value)} />
                                <label htmlFor={"Sport"} className={model === "Sport" ? "fields-radio checked" : 'fields-radio'}>Sport</label>
                            </div>
                        </div>
                        <div className={date ? "datepicker" : "datepicker red"}>
                            <DatePicker id="datePicker" className={"input"} placeholderText="Inköpsdatum"
                                minDate={setMinDate()} maxDate={new Date()} dateFormat="dd/MM/yyyy"
                                selected={date} onChange={(date) => setDate(date as any)} />
                        </div>
                        <input className={loading ? "payment-button loading" : "payment-button"} type="button" value="Betala med Trustly" onClick={() => purchase()} />
                    </div>
                </>
                :
                <>
                    <div className="row">
                        <input id="name" className={name ? "input" : "input red"} type="text" placeholder=" Förnamn"
                            value={name} onChange={(item) => setName(item.currentTarget.value)} />
                        <input id="surname" className={surname ? "input" : "input red"} type="text" placeholder=" Efternamn"
                            value={surname} onChange={(item) => setSurname(item.currentTarget.value)} />
                    </div>
                    <div className="row">
                        <input id="email" className={email ? "input" : "input red"} type="text" placeholder=" E-postadress"
                            value={email} onChange={(item) => setEmail(item.currentTarget.value)} />
                        <div className={date ? "datepicker" : "datepicker red"}>
                            <DatePicker id="datePicker" className={"input"} placeholderText="Inköpsdatum"
                                minDate={setMinDate()} maxDate={new Date()} dateFormat="dd/MM/yyyy"
                                selected={date} onChange={(date) => setDate(date as any)} />
                        </div>
                    </div>
                    <div className="row">
                        <div id="radioButtons" className={model ? "radio-buttons" : "radio-buttons red"}>
                            <div className="radio-text">
                                Välj din Vélosophy-modell:
                            </div>
                            <div className="radio-buttons-inner">
                                <input style={{ display: 'none' }} type="radio" id="Comfort" name="model"
                                    value="Comfort" onChange={(item) => setModel(item.currentTarget.value)} />
                                <label htmlFor={"Comfort"} className={model === "Comfort" ? "fields-radio checked" : 'fields-radio'}>Comfort</label>
                                <input style={{ display: 'none' }} type="radio" id="Sport" name="model"
                                    value="Sport" onChange={(item) => setModel(item.currentTarget.value)} />
                                <label htmlFor={"Sport"} className={model === "Sport" ? "fields-radio checked" : 'fields-radio'}>Sport</label>
                            </div>
                        </div>
                        <input className={loading ? "payment-button loading" : "payment-button"} type="button" value="Betala med Trustly" onClick={() => purchase()} />
                    </div>
                </>
            }
        </div>
    )
}