import React, { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { OutlinedInputProps, InputLabel } from "@material-ui/core";
import { useIntl } from "react-intl";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { helperTextStyles, useStylesTextField } from "../styles/styledComponents/stylesForComponents";
import flatpickr from "flatpickr";
import { PropertyMap } from "../../BackendApi/OmocomStoreApi";

interface IDateComponentProps {
	/** Input value */
	value: moment.Moment | undefined;

	/** Function that is called when the value is changed */
	onChange: Function;

	/** Boolean that checks if the form has been posted */
	done: boolean;

	/** Id of the control */
	id: string;

	/** Label text for the control */
	label: string;

	/** Help text to describe what the user should enter */
	help?: string;

	/** Name of the form field */
	fieldName: string;

	/** Placeholder text for when the field is empty */
	placeholder: string;

	/** True if the field is required, false otherwise */
	isRequired?: boolean;

	/** True if the field is disabled, false otherwise */
	disabled?: boolean;

	// eslint-disable-next-line react/no-unused-prop-types
	flatPickerOptions?: flatpickr.Options.Options;

	/** Enum that checks where the value should be saved */
	map?: PropertyMap;

	/** Sets the error if there is one */
	setError: Function;

	/** Indicates whether the errors should be displayed or not */
	displayErrors: boolean;
}

const DateComponent: React.FC<IDateComponentProps> = (props) => {
	const intl = useIntl();
	const classes = useStylesTextField();
	const helperTestClasses = helperTextStyles();
	const {
		value,
		onChange,
		done,
		id,
		label,
		fieldName,
		placeholder,
		isRequired,
		disabled,
		help,
		flatPickerOptions,
		map,
		setError,
		displayErrors
	} = props;

	const handleDateChange = (date: any) => {
		setError("", id);
		onChange(moment(date, moment.ISO_8601), id, null, map);
	};

	const helperText = () => {
		if (!value && isRequired && displayErrors) {
			return (
				<span className="helperTextDisplayError">
					{intl.formatMessage({
						id: "StoreForm.validateFieldRequired",
					})}
				</span>
			);
		}

		return <span className="helperTextDefaultStyle" />;
	};

	useEffect(() => {
		isRequired && setError(intl.formatMessage({ id: "StoreForm.validateFieldRequired" }), id);
	// eslint-disable-next-line
	}, []);

	return (
		<div id={id} className={displayErrors ? "was-validated" : ""}>
			<InputLabel>{label}</InputLabel>
			<DatePicker
				helperText={helperText()}
				name={fieldName}
				required={isRequired}
				disabled={disabled || done}
				placeholder={placeholder}
				onChange={handleDateChange}
				value={value || null}
				InputLabelProps={{
					shrink: true,
				}}
				FormHelperTextProps={{
					classes: helperTestClasses,
				}}
				className={helperTestClasses.contained}
				InputProps={
					{
						classes,
					} as Partial<OutlinedInputProps>
				}
				format="YYYY-MM-DD"
				variant="inline"
				inputVariant="outlined"
				fullWidth
				autoOk
				disableFuture
				minDate={flatPickerOptions?.minDate}
			/>
			{help && <Alert variant="warning">{help}</Alert>}
		</div>
	);
};

export default DateComponent;
