import { NOTIFICATION_TYPE, Store } from 'react-notifications-component';
import { PropertyMap } from '../BackendApi/OmocomStoreApi';

const addNotification = (message: any, title: string, type: NOTIFICATION_TYPE) => {
    Store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

const convertToKeyValue = (fields: any, map: PropertyMap) => {
    let tempFields = [] as any;
    Object.entries(fields).forEach(
        ([key, value]: any) => {
            if (value.map === map) {
                if (map === PropertyMap.InsurableObjectShortDescription) {
                    tempFields = value.item;
                }
                else if (value.item._isAMomentObject) {
                    tempFields.push({
                        name: key,
                        description: key,
                        dateTimeValue: value.item
                    })
                } else if (value.item.amount) {
                    value.item.amount = value.item.amount.toString().replace(/\.+$/, "");
                    tempFields.push({
                        name: key,
                        description: key,
                        monetaryValue: {
                            amount: parseFloat(value.item.amount),
                            currency: value.item.currency
                        }
                    })
                } else {
                    tempFields.push({
                        name: key,
                        description: key,
                        value: value.item as string
                    })
                }
            }
        }
      );

    return tempFields;
}

export { addNotification, convertToKeyValue }
