import React from "react";
import thumbs from "../../Utils/Images/approved.svg";
import Footer from "../Footer/Footer";

function Success() {
    return (
        <div className="velosophy-successWrapper">
            <div className="success">
                <img className="thumbs" src={thumbs} alt={''} />
                <div className="successText">
                    <h1>
                        Tack och grattis!
                    </h1>
                    <p>
                        Ditt köp har lyckats!
                    </p>
                    <p>
                    Vi har skickat en bekräftelse med dina fullständiga 
                    försäkringsvillkor till {sessionStorage.veloMail || "din emailaddress"}.
                    </p>
                </div>
            </div>
            <div className="footer" >
                <Footer />
            </div>
        </div>
    )
}

export default Success;