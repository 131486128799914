import React, {useEffect, useState} from "react";
import "./Velosophy";
import background from "../Utils/Images/bicycle-bg.png";
import Text from "./Text/Text";
import Fields from "./Fields/Fields";
import Information from "./Information/Information";
import Footer from "./Footer/Footer";
import omocomLogo from "../Utils/Images/omocom-logo-orange.png";
import velosophyLogo from "../Utils/Images/velosophy-logo.png";

export default function Velosophy() {
    const [isPhone, setIsPhone] = useState(false);

    useEffect(() => {
        const updateWindowDimensions = () => {
            if (window.innerWidth < 800) {
                setIsPhone(true);
            }
            else {
                setIsPhone(false);
            }
        };

        window.addEventListener("resize", updateWindowDimensions);
        updateWindowDimensions();
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);

    return (
        <div className="velosophy">
            <div className="background-image" style={{ backgroundImage: `url(${background})` }} />
            <div className="banner">
                <div className="logo-wrapper">
                    <img src={omocomLogo} alt="" />
                    <img src={velosophyLogo} alt="" />
                </div>
            </div>
            <div className="content-wrapper">
                <Text />
                <Fields isPhone={isPhone} />
                <Information isPhone={isPhone} />
                <Footer />
            </div>
        </div>
    )
}