import "flatpickr/dist/flatpickr.min.css";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "./styles/css/StoreForm.css";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { LogoBig, PrimaryButton } from "./styles/styledComponents/styled";
import StoreModal from "./StoreModal";
import ItemInputInformation from "./StoreComponents/ItemInputInformation";
import UserInputInformation from "./StoreComponents/UserInputInformation";
import Basket from "./StoreComponents/Basket";
import { initialValues, IModal } from "./models/StoreFormDefaults";
import { DeploymentEnvironment } from "../BackendApi/DeploymentEnvironment";
import { OmocomStoreApiClientConfig } from "../BackendApi/OmocomStoreApiClientConfig";
import { InsuranceClient, IMonetaryValue, ProductInfoClient, IProductInfoResp, IProductInfo, MonetaryValue, PropertyMap, ComponentType } from "../BackendApi/OmocomStoreApi";
import { validateFields } from "../Utils/validation/validation";
import { addNotification, convertToKeyValue } from "../Utils/Utils";
import authentication from "../B2c";

const StoreForm = () => {
    const [form, setForm] = useState(initialValues as any);

    const [availableProducts, setAvailableProducts] = useState({} as IProductInfoResp);
    const [selectedProduct, setSelectedProduct] = useState({} as IProductInfo);
    const [productIsSelected, setProductIsSelected] = useState(false);

    const [productValues, setProductValues] = useState({}) as any;
    const [insuredAmount, setInsuredAmount] = useState({amount: 0, currency: "SEK"} as IMonetaryValue);

    const [modal, setModal] = useState({} as IModal);
    const [errors, setErrors] = useState({});
    const [itemErrors, setItemErrors] = useState({});
    const [done, setDone] = useState(false);
    const [displayErrors, setDisplayErrors] = useState(false);
    const [displayItemErrors, setDisplayItemErrors] = useState(false);

    const intl = useIntl();

    const convertValue = (value: string, id: string) => {
        if (id === "ssn") {
            setForm((form: any) => ({ ...form, ssn:{ssn: value, country: ""} }));
        }
        else {
            setForm((form: any) => ({ ...form, [id]: value }));
        }
    }

    // Runs when any input field is changed
    const convertProductValue = (value: any, id: string, fieldType: ComponentType | string, map: PropertyMap) => {
        if (map === PropertyMap.InsuranceInsuredAmount) {
            if (value.amount) {
                setInsuredAmount({ amount: value.amount, currency: value.currency });
            } else {
                setInsuredAmount({ amount: value, currency: availableProducts.marketCurrency || "SEK" })
            }
        } else if (fieldType === ComponentType.MonetaryValue) {
            setProductValues((productFields: any) => ({
                ...productFields, [id]: { item: { amount: value, currency: availableProducts.marketCurrency } as MonetaryValue, map }
            }))
        } else {
            setProductValues((productFields: any) => ({ ...productFields, [id]: { item: value, map } }));
        }
    }

    // Runs when product is changed
    const handleProductSelection = (event: any) => {
        setProductIsSelected(true);
        availableProducts.products && availableProducts.products.forEach(product => {
            if (product.productName === event.currentTarget.textContent) {
                setSelectedProduct(product);
                product.fields && product.fields.forEach(field => {
                    if (field.externalPropertyMap === PropertyMap.InsuranceInsuredAmount && field.required) {
                        setItemErrors(error => ({ ...error, "insuredAmount": intl.formatMessage({ id: "StoreForm.IsRequiredField" }) }));
                    } else if (field.componentType === "Selection" && field.required) {
                        setItemErrors(error => ({ ...error, [field.externalPropertyName || field.id || ""]: intl.formatMessage({ id: "StoreForm.IsEmptyItemFields" }) }));
                    }
                });
            }
        })
        resetProductValues();
    }

    // Validates Item fields and adds them to the purchasedProducts list
    const saveProduct = () => {
        if(!productIsSelected) {
            addNotification(intl.formatMessage({ id: "StoreForm.ProductNotSelected" }), intl.formatMessage({ id: "StoreForm.Error" }), "danger");
            return;
        } else if (!validateFields(itemErrors, intl)) {
            setDisplayItemErrors(true);
            return;
        }
        
        let tempProduct = [...form.purchasedProducts];
        tempProduct.push({
            productName: selectedProduct.productName,
            omocomCounterpartHandle: selectedProduct.omocomCounterpartHandle,
            addOns: selectedProduct.addOns,
            shortDescription: convertToKeyValue(productValues, PropertyMap.InsurableObjectShortDescription),
            insuredAmount: insuredAmount && insuredAmount.amount && insuredAmount.amount > 0 ? insuredAmount : undefined,
            InsuranceExternalProperties: convertToKeyValue(productValues, PropertyMap.InsuranceExternalProperty),
            InsurableObjectExternalProperties: convertToKeyValue(productValues, PropertyMap.InsurableObjectExternalProperty)
        });

        setForm({
            ...form,
            purchasedProducts: tempProduct
        })

        setItemErrors({});
        resetProductValues();
        setDisplayItemErrors(false);

        selectedProduct.fields && selectedProduct.fields.forEach(field => {
            if (field.externalPropertyMap === PropertyMap.InsuranceInsuredAmount && field.required) {
                setItemErrors(error => ({ ...error, "insuredAmount": intl.formatMessage({ id: "StoreForm.IsRequiredField" }) }));
            } else if (field.componentType === "Selection" && field.required) {
                setItemErrors(error => ({ ...error, [field.externalPropertyName || field.id || ""]: intl.formatMessage({ id: "StoreForm.IsEmptyItemFields" }) }));
            }
        });

        addNotification(intl.formatMessage({ id: "StoreForm.ProductAdded" }), intl.formatMessage({ id: "StoreForm.Success" }), "success");
        return tempProduct;
    }

    const resetProductValues = () => {
        setProductValues({});
        setInsuredAmount({amount: 0, currency: "SEK"});
        setItemErrors({});

        let buttonsContainers = document.getElementsByClassName("selection-buttons-wrapper");
        for (let buttonsContainer of buttonsContainers) {
            let buttons = buttonsContainer.getElementsByClassName("selection-button");
            for (let button of buttons) {
                button.classList.remove('active');
            }
        };
    }

    const removeItem = (index: number) => {
		let tempSavedItems = [...form.purchasedProducts];
		tempSavedItems.splice(index, 1);
		setForm({...form, purchasedProducts: tempSavedItems});
	}

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                const deploymentEnv = new DeploymentEnvironment();
                const insuranceInfoClient = new OmocomStoreApiClientConfig(deploymentEnv.apiUrl);
                const productClient = new ProductInfoClient(insuranceInfoClient);

                const token = await authentication.getAccessToken() as any;
                let platformId = token?.idTokenClaims?.extension_PlatformId;
                let marketName = token?.idTokenClaims?.extension_MarketName;

                const response = await productClient.getProductInfo(platformId, marketName);
                setAvailableProducts(response);

            } catch (e) {
                console.log("error: ", e)
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line
    }, []);

    const handleFormSubmit = async (
        e: React.SyntheticEvent<HTMLFormElement>
    ) => {
        e.preventDefault();

        if (done) {
            return;
        }

        if (!productIsSelected) {
            addNotification(intl.formatMessage({ id: "StoreForm.ProductNotSelected" }), intl.formatMessage({ id: "StoreForm.Error" }), "danger");
            return;
        } 

        if (!validateFields(errors, intl)) {
            setDisplayErrors(true);
            return;
        }

        if (productValues.length || insuredAmount.amount) {
            if (!validateFields(itemErrors, intl)) {
                setDisplayItemErrors(true);
                return;
            }

            let tempProduct = saveProduct();
            if (!tempProduct) {
                return;
            }

            form.purchasedProducts = tempProduct;

        } else if (!form.purchasedProducts.length) {
            addNotification(intl.formatMessage({ id: "StoreForm.IsEmptyItemFields" }), intl.formatMessage({ id: "StoreForm.Error" }), "danger");
            return;
        }

        setModal({
            ...modal,
            showModal: true,
            modalText: intl.formatMessage({
                id: "StoreForm.ModalTextSave",
            }),
            modalHeader: "",
            submitting: true,
        });

        setDisplayErrors(false);
        setDisplayItemErrors(false);

        let token = await authentication.getAccessToken() as any;

        try {
            const deploymentEnv = new DeploymentEnvironment();
            const authClientConfig = new OmocomStoreApiClientConfig(deploymentEnv.apiUrl, token.accessToken);
            const insuranceClient = new InsuranceClient(authClientConfig);

            let result = await insuranceClient.create(availableProducts.platformId, availableProducts.marketName, form);
            console.log("result", result);
            let modalInfoText = [] as any;
            result.createdInsurances?.forEach(insurance => {
                modalInfoText.push({ id: insurance.id, product: insurance.product });
            });

            setModal({
                ...modal,
                logoResponse: <LogoBig />,
                modalHeader: intl.formatMessage({
                    id: "StoreForm.ModalHeaderFormReceived",
                }),
                modalResponseBody: intl.formatMessage({
                    id: "StoreForm.ModalResponseBodyFormReceived",
                }),
                modalInfoText: modalInfoText,
                modalResponseFooter: intl.formatMessage({
                    id: "StoreForm.ModalResponseFooterFormReceived",
                }),
                modalResponseFooterLink: "https://www.omocom.insurance",

                showModal: true,
                submitting: false,
            });
            setDone(true);

        } catch (ex) {
            // I am still not clear on why the generated code does not return a ValidationProblemDetails structure like it is done for
            // Omocom.Api/OpenApi/omocomApi.ts. If I can figure that out we might change this exception.
            console.log("error: ", ex);
            // Display what the problem is in the error popup so we don't have to spin up the debugger to look at the exception
            if (ex instanceof Error) {
                setModal({
                    ...modal,
                    showModal: true,
                    modalText: ex.message,
                    modalHeader: intl.formatMessage({
                        id: "StoreForm.InstanceOfError",
                    }),
                    submitting: false,
                });
            }
            else {
                setModal({
                    ...modal,
                    showModal: true,
                    modalText: intl.formatMessage({
                        id: "StoreForm.UnknownError",
                    }),
                    modalHeader: intl.formatMessage({
                        id: "StoreForm.InstanceOfError",
                    }),
                    submitting: false,
                })
            }
        }
    };

    return (
        <>
            <form
                autoComplete="off"
                className="mb-4"
                onSubmit={(e) => handleFormSubmit(e)}
                noValidate
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                        event.preventDefault();
                    }
                }}
            >

                <UserInputInformation
                    form={form}
                    setValue={convertValue}
                    done={done}
                    setError={(newError: any, id: string) => { setErrors(error => ({ ...error, [id]: newError })) }}
                    displayErrors={displayErrors}
                />

                <ItemInputInformation
                    products={availableProducts.products}
                    selectedProduct={selectedProduct}
                    productValues={productValues}
                    insuredAmount={insuredAmount}
                    setValue={convertProductValue}
                    handleProductSelection={handleProductSelection}
                    done={done}
                    setError={(newError: any, id: string) => { setItemErrors(error => ({ ...error, [id]: newError })) }}
                    displayErrors={displayItemErrors}
                />

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Card>
                            <CardContent>
                                <Typography
                                    className="MuiTypography--heading"
                                    variant="h6"
                                    gutterBottom
                                >
                                    {` ${intl.formatMessage({
                                        id: "StoreForm.ReviewInformation",
                                    })}`}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <PrimaryButton
                    className="btn btn-block"
                    type="button"
                    value={intl.formatMessage({
                        id: "StoreForm.addValue",
                    })}
                    onClick={saveProduct}
                    disabled={done}
                />

                <PrimaryButton
                    className="btn btn-block"
                    type="submit"
                    value={intl.formatMessage({
                        id: "StoreForm.submitValue",
                    })}
                    disabled={done}
                />
            </form>

            <Basket products={form.purchasedProducts} removeItem={removeItem} done={done} />

            <StoreModal
                show={modal.showModal}
                text={modal.modalText}
                logoResponse={modal.logoResponse}
                header={modal.modalHeader}
                textIssueKey={modal.modalResponseIssueKey}
                info={modal.modalInfoText}
                body={modal.modalResponseBody}
                footer={modal.modalResponseFooter}
                footerLinkText={modal.modalResponseFooterLinkText}
                footerLink={modal.modalResponseFooterLink}
                onCloseModal={() => {
                    if (!modal.submitting) {
                        setModal({
                            ...modal,
                            showModal: false,
                        });
                    }
                }}
            />
        </>
    );
};

export default StoreForm
