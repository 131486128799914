import React, { useEffect } from "react";
import { InsuranceInformation } from "./InsuranceInformation";
import { InputLabel, MenuItem, TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { FieldGroupString } from "../CommonComponents/FieldGroupString";
import { useIntl } from "react-intl";
import DateComponent from "../CommonComponents/DateComponent";
import { ComponentType, IProductInfo, PropertyMap } from "../../BackendApi/OmocomStoreApi";

interface IItemInputInformationProps {
    products: any;
    productValues: any;
    insuredAmount: any;
    setValue: Function;
    selectedProduct: IProductInfo;
    handleProductSelection: any;
    done: boolean;
    setError: Function;
    displayErrors: boolean;
}

const ItemInputInformation: React.FC<IItemInputInformationProps> = (props) => {
    /** Initialization */
    const { products, productValues, insuredAmount, setValue, selectedProduct, handleProductSelection, done, setError, displayErrors } = props;
    
    const intl = useIntl();
    const setItemFields = () => {
        let tempFields = [] as any;

        if (selectedProduct.fields?.length) {
            selectedProduct.fields.forEach((item: any) => {
                // TODO: add different fields depending on the field type
                switch (item.componentType) {
                    case ComponentType.DateTimeOffset:
                        tempFields.push(
                            <DateComponent
                                value={productValues[item.externalPropertyName]?.item || productValues[item.id]}
                                onChange={setValue}
                                done={done}
                                id={item.externalPropertyName || item.id}
                                key={item.id}
                                fieldName={item.id}
                                label={item.id + (item.required ? " *" : "")}
                                isRequired={item.required}
                                placeholder={item.id}
                                flatPickerOptions={{
                                    allowInput: true,
                                    minDate: "1900-01-01"
                                }}
                                map={item.externalPropertyMap}
                                setError={setError}
                                displayErrors={displayErrors}
                            />
                        );
                        break;
                    case ComponentType.Selection:
                        tempFields.push(
                            <InsuranceInformation
                                key={item.id}
                                value={item.fixedLadderPremium || ""}
                                setValue={setValue}
                                fieldName={item.externalPropertyMap === PropertyMap.InsuranceInsuredAmount ? "insuredAmount" : item.externalPropertyName || item.id}
                                isRequired={item.required}
                                done={done}
                                map={item.externalPropertyMap}
                                setError={setError}
                                displayErrors={displayErrors}
                            />
                        );
                        break;
                    case ComponentType.MonetaryValue:
                        tempFields.push(
                            <Grid container className="objectPrice" key={item.id + "objectPrice"}>
                                <InputLabel key={item.id + " label"}>
                                    {item.id + (item.required ? " *" : "")}
                                </InputLabel>
                                <Grid item md={10} xs={10}>
                                    <FieldGroupString
                                        value={item.externalPropertyMap === PropertyMap.InsuranceInsuredAmount ? insuredAmount.amount || "" : productValues[item.externalPropertyName]?.item?.amount || productValues[item.id]?.item?.amount || ""}
                                        onChange={setValue}
                                        done={done}
                                        id={item.externalPropertyMap === PropertyMap.InsuranceInsuredAmount ? "insuredAmount" : item.externalPropertyName || item.id}
                                        key={item.id}
                                        fieldType={item.componentType}
                                        isRequired={item.required}
                                        placeholder={item.id}
                                        map={item.externalPropertyMap}
                                        setError={setError}
                                        displayErrors={displayErrors}
                                    />
                                </Grid>
                                <Grid item md={2} xs={2}>
                                    <FieldGroupString
                                        value={item.externalPropertyMap === PropertyMap.InsuranceInsuredAmount ? insuredAmount.currency : productValues[item.externalPropertyName]?.item.currency || productValues[item.id]?.currency || "SEK"}
                                        onChange={() => {}}
                                        done={done}
                                        id={item.externalPropertyName || item.id}
                                        key={item.id + " currency"}
                                        fieldType={item.componentType}
                                        isRequired={item.required}
                                        placeholder={item.id}
                                        displayErrors={false}
                                    />
                                </Grid>
                            </Grid>
                        );
                        break;
                    default:
                        tempFields.push(
                            <FieldGroupString
                                value={productValues[item.externalPropertyName]?.item || productValues[item.id]?.item || ""}
                                onChange={setValue}
                                done={done}
                                multiLineTextBox={item.id === "Description"}
                                id={item.externalPropertyName || item.id}
                                key={item.id}
                                fieldType={item.componentType}
                                label={item.id + (item.required ? " *" : "")}
                                isRequired={item.required}
                                placeholder={item.id}
                                map={item.externalPropertyMap}
                                setError={setError}
                                displayErrors={displayErrors}
                            />
                        );
                        break;
                }
            });
        }
        return tempFields;
    }

    useEffect(() => {
		const queryString = require('query-string');
		const parsed = queryString.parse(window.location.search);

		Object.keys(parsed).length !== 0 &&
			Object.entries(parsed).forEach(([queryKey, queryValue]) => {
                selectedProduct.fields?.forEach((item: any) => {
                    if (item.externalPropertyName) {
                        if (item.externalPropertyName.toLowerCase() === queryKey.toLowerCase()) {
                            //will add other componentTypes at a later date
                            if (item.componentType === ComponentType.Text || item.componentType === ComponentType.MonetaryValue) {
                                setValue(queryValue, item.externalPropertyName, item.componentType, item.externalPropertyMap);
                                setError(false, item.externalPropertyName);
                            }
                        }
                    } else if (item.id.toLowerCase() === queryKey.toLowerCase()) {
                        //will add other componentTypes at a later date
                        if (item.componentType === ComponentType.Text || item.componentType === ComponentType.MonetaryValue) {
                            setValue(queryValue, queryKey, item.componentType, item.externalPropertyMap);
                            setError(false, item.componentType === ComponentType.MonetaryValue ? "insuredAmount" : item.id);
                        }
                    }
                })
			});
		// eslint-disable-next-line
	}, [selectedProduct])

    return (
        <Grid container spacing={3}>
            <Grid item md={7} xs={12}>
                <TextField
                    value={selectedProduct.productName || ""}
                    onChange={handleProductSelection}
                    select
                    label="Products"
                    disabled={done}
                    fullWidth
                >
                    {
                        !products ?
                            <MenuItem key={""} value={""} /> :
                            products.map((c: any) => (
                                <MenuItem key={c.productName} value={c.productName}>
                                    {c.productName}
                                </MenuItem>
                            ))
                    }
                </TextField>
                {!selectedProduct.fields?.length && displayErrors &&
                    <p className="MuiFormHelperText-root">
                        <span className="helperTextDisplayError">
                            {intl.formatMessage({ id: "StoreForm.IsRequiredField" })}
                        </span>
                    </p>
                }
            </Grid>
            <Grid item md={7} xs={12}>
                {setItemFields()}
            </Grid>
        </Grid>
    );
};
    

export default ItemInputInformation;