import React from "react";
import styles from "./Failure.module.css";
import thumbs from "../../Utils/Images/failure.svg";
import Footer from "../Footer/Footer";

function Failure() {
    return (
        <div className={styles.failureWrapper}>
            <div className={styles.failure}>
                <div>
                    <img className={styles.thumbs} src={thumbs} alt={''} />
                </div>
                <h1>
                    Oops, något gick fel.
                </h1>
                <p>
                    Prova gärna igen, och dubbelkolla att
                    du valde rätt konto och att det finns täckning.
                </p>
                <p>
                    Om felet kvarstår kontakta Omocom via <a className={styles.mail} href="mailto:hello@omocom.insurance">hello@omocom.insurance</a> eller 08-520 278 70.
                </p>
                <a className={styles.button} href={sessionStorage.referral || "https://www.blocket.se/"}>Återvänd till Blocket</a>
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </div>
    )
}

export default Failure;