import React from 'react';
import * as msal from '@azure/msal-browser';

const state = {
    config: {
        scopes: [],
        cacheLocation: null,
    },
    msalApp: null,
	accountId: null
}

var isIE = function isIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ") > -1;
    var msie11 = ua.indexOf("Trident/") > -1;
    return msie || msie11;
};

const isProduction = window.location.origin.toLowerCase() === 'https://store.omocom.se';
const TENANT_NAME = isProduction ? 'OmocomB2C' : 'OmocomB2CDev';
const TENANT_DOMAIN = TENANT_NAME + '.onmicrosoft.com';
const AUTHORITY_DOMAIN = TENANT_NAME + '.b2clogin.com';
const clientId = isProduction ? '8f295246-d0a8-4903-b27d-1bb5e883cb14' : '38150f39-2569-459f-a5f4-01d2597719d4'; 

var B2C_SCOPES = {
    API_ACCESS: {
        scopes: ['https://' + TENANT_DOMAIN + '/' + clientId + '/Store']
    }
};

const LOGIN_FLOW = "B2C_1_SignIn";
const msalAppConfig = {
    auth: {
        clientId: clientId,
		authority: "https://" + AUTHORITY_DOMAIN + "/" + TENANT_DOMAIN + "/" + LOGIN_FLOW,
        knownAuthorities: [AUTHORITY_DOMAIN],
		redirectUri: window.location.origin + '/store/se',
        postLogoutRedirectUri: window.location.origin + '/store/se'
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE()
    }
}

function setAccount(account) {
	console.log("setAccount");
	state.accountId = account.homeAccountId;
	state.msalApp.setActiveAccount(account);
}

function selectAccount () {
	console.log("selectAccount");
	const currentAccounts = state.msalApp.getAllAccounts();

	if (currentAccounts.length > 1) {
		/**
		 * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
		 * is cached as a new account, which results in more than one account in the cache. Here we make
		 * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow,
		 * as this is the default flow the user initially signed-in with.
		 */
		const accounts = currentAccounts.filter(account =>
			account.homeAccountId.toUpperCase().includes(LOGIN_FLOW.toUpperCase())
			&&
			account.idTokenClaims.iss.toUpperCase().includes(AUTHORITY_DOMAIN.toUpperCase())
			&&
			account.idTokenClaims.aud === msalAppConfig.auth.clientId
		);

		if (accounts.length > 1) {
			// localAccountId identifies the entity for which the token asserts information.
			if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
				// All accounts belong to the same user
				setAccount(accounts[0]);
			} else {
				// Multiple users detected. Logout all to be safe.
				signOut();
			}
		} else if (accounts.length === 1) {
			setAccount(accounts[0]);
		}

	} else if (currentAccounts.length === 1) {
		setAccount(currentAccounts[0]);
	}
}

function handleResponse(response) {
	console.log("handleResponse");
	if (response !== null) {
		setAccount(response.account);
	} else {
		selectAccount();
	}
}

function signIn() {
	console.log("signIn");
	state.msalApp.loginRedirect(B2C_SCOPES.API_ACCESS);
}

function signOut() {
	const logoutRequest = {
		postLogoutRedirectUri: msalAppConfig.auth.redirectUri,
	};

	state.msalApp.logoutRedirect(logoutRequest);
}

function getToken(successCallback) {
	console.log("getToken");

	let request = B2C_SCOPES.API_ACCESS;

	return state.msalApp.acquireTokenSilent(request)
		.then(response => {
			console.log("HandleSilentTokenResponse");

			// In case the response from B2C server has an empty accessToken field
			// throw an error to initiate token acquisition
			if (!response.accessToken || response.accessToken === "") {
				throw new msal.InteractionRequiredAuthError();
			} else {
				console.log("access_token acquired at: " + new Date().toString());
				
				if (successCallback) {
					successCallback();
				}

				return response;
			}
		})
		.catch(error => {
			console.warn("silent token acquisition fails. acquiring token using redirect");
			if (error instanceof msal.InteractionRequiredAuthError) {
				// fallback to interaction when silent call fails
				console.log("aquireTokenRedirect");

				return state.msalApp.acquireTokenRedirect(request);
			} else {
				console.warn(error);
			}
		});
}

var authentication = {
    initialize: () => {
		state.msalApp = new msal.PublicClientApplication(msalAppConfig);
	},
    required: (WrappedComponent, renderLoading) => {
        return class extends React.Component {
            constructor(props) {
                super(props)
                this.state = {
                    signedIn: false,
                    error: null
                }
			}

            async componentDidMount() {
				console.log("componentDidMount");
				await state.msalApp.handleRedirectPromise()
					.then(response => {
						console.log("HandledRedirectPromise");

						if (response) {

							/**
							 * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
							 * from SignIn flow. "tfp" claim in the id token tells us the policy
							 */
							if (response.idTokenClaims['tfp'].toUpperCase() === LOGIN_FLOW.toUpperCase()) {
								handleResponse(response);
							}
						}
					})
					.catch((error) => {
						console.error(error);
					});

				selectAccount();
				
				if (state.accountId === null) {
					signIn();
				} else {
					await getToken(() => {
						console.log("Set state signed in");

						this.setState({
							signedIn: true
						})
					});
				}
			}

            render() {
				console.log("render");

				if (this.state.signedIn) {
                    return (<WrappedComponent {...this.props} />)
                }
                return typeof renderLoading === 'function' ? renderLoading() : null
            }
        }
    },

    signOut: function signOut() {
        return state.msalApp.logout();
    },
	getAccessToken: async () => await getToken()
};

export default authentication;