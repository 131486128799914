export enum DeploymentEnv {
	Local,
	AzurePrivate,
	AzureDevelopment,
	Beta,
	Systest,
	Staging,
	Production,
}

export class DeploymentEnvironment {
	private protocol: string;
	private environmentType: DeploymentEnv;
	private environmentName: string;

	constructor(protocol?: string, url?: string) {
		this.protocol = protocol || window.location.protocol;
		[
			this.environmentType,
			this.environmentName,
		] = this.GetEnvironmentTypeAndName(url || window.location.host);
	}

	public get Type(): DeploymentEnv {
		return this.environmentType;
	}

	public get apiUrl(): string {
		switch (this.environmentType) {
			case DeploymentEnv.Local: {
				return `${this.protocol}//localhost:5021`;
			}
			case DeploymentEnv.AzurePrivate: {
				return `${this.protocol}//omocom-store-api-${this.environmentName}.azurewebsites.net`;
			}
			case DeploymentEnv.AzureDevelopment:
			case DeploymentEnv.Beta:
			case DeploymentEnv.Systest:
			case DeploymentEnv.Staging: {
				return `${this.protocol}//store-api-${this.environmentName}.omocom.se`;
			}
			case DeploymentEnv.Production: {
				return `${this.protocol}//store-api.omocom.se`;
			}
			default: {
				throw new Error(
					"Unknown DeploymentEnv: " + this.environmentType
				);
			}
		}
	}

	// --------------------------------------------------------------------------------

	private GetEnvironmentTypeAndName(url: string): [DeploymentEnv, string] {
		const compareString = url.toLowerCase();
		switch (compareString) {
			case "localhost:5020":
				return [DeploymentEnv.Local, "local"];
			case "store-develop.dev.omocom.se":
				return [DeploymentEnv.AzureDevelopment, "develop"];
			case "store-beta.omocom.se":
				return [DeploymentEnv.Beta, "beta"];
			case "store-systest.dev.omocom.se":
				return [DeploymentEnv.Systest, "systest"];
			case "store-staging.omocom.se":
				return [DeploymentEnv.Staging, "staging"];
			case "store.omocom.se":
				return [DeploymentEnv.Production, "production"];
		}

		const urlRegExp = /omocom-store-(.+).azurewebsites.net/;
		const match = urlRegExp.exec(compareString);
		if (match == null) {
			throw new Error(`Unknown URL passed to environment: ${url}`);
		}

		return [DeploymentEnv.AzurePrivate, match[1]];
	}
}
