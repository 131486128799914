import styled from "styled-components";
import { createMuiTheme } from "@material-ui/core/styles";

export const ThemedBackground = styled.div`
	background: #FFF;
`;

export const PrimaryButton = styled.input`
	width: 190px;
	height: 60px;
	color: #fff;
	border-radius: 4px;
	border-color: #CB7469;
	background-color: #CB7469;
`;

export const Logo = styled.div`
	background-image: url(${(props) => props.theme.logoBlack});
	width: auto;
	height: 2rem;
	background-position: left;
	background-repeat: no-repeat;
	margin: 2rem auto;

	@media only screen and (max-width : 1040px) {
		height: 1.5rem;
		background-position: center;
	}
`;

export const LogoWhite = styled.div`
	background-image: url(${(props) => props.theme.logoWhite});
	width: auto;
	height: 2.5rem;
	background-position: right;
	background-repeat: no-repeat;

	@media (max-width: 1040px) {
		height: 2rem;
	}
`;

export const LogoBig = styled.div`
	background-image: url(${(props) => props.theme.logoBlack});
	width: auto;
	height: 6rem;
	background-position: center;
	background-repeat: no-repeat;
`;

export const VersionStyle = styled.div`
	font-size: 0.8125rem;
	margin-left: 0.3rem;
`;

export const Theme = createMuiTheme({
	overrides: {
		MuiInputBase: {
			root: {
				fontFamily: '"DM Sans", sans-serif',
				fontSize: '1.25rem',
				color: '#64676A',
				"&:hover": {
					outlineColor: "#30A2F3",
					backgroundColor: "#F8F9FB",
				},
				"& .MuiInputBase-input:focus": {
					background: '#FFFFFF',
					color: "#64676A",
					border: "1px solid #DADEE0",
					padding: '15.5px 14px'
				}
			}
		},
		MuiFormLabel: {
			root: {
				fontSize: '0.75rem',
			}
        },
		MuiButton: {
			root: {
				textTransform: "capitalize",
				fontSize: "1rem",
				fontFamily: "inherit",
			},
			contained: {
				color: "#BDC4C9",
				backgroundColor: "#FFFFFF",
				marginRight: 10,
				padding: "1.25rem",
				border: "1px solid",
				borderColor: "#BDC4C9",
				boxShadow: "none",
				"&:hover": {
					color: "#F5E5E3",
					backgroundColor: "#E58E83",
					boxShadow: "none",
				},
				"&:focus": {
					color: "#F5E5E3",
					backgroundColor: "#CB7469",
				},
				"&.active": {
					color: "#F5E5E3",
					backgroundColor: "#CB7469",
				},
			},
		},
	},
});
