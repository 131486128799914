import React from "react";
import styles from "./Success.module.css";
import thumbs from "../../Utils/Images/approved.svg";
import Footer from "../Footer/Footer";

function Success() {
    return (
        <div className={styles.successWrapper}>
            <div className={styles.success}>
                <img className={styles.thumbs} src={thumbs} alt={''} />
                <div className={styles.successText}>
                    <h1>
                        Tack och grattis!
                    </h1>
                    <p>
                        Nu är du försäkrad mot dolda fel på din produkt i upp till 14 dagar.
                    </p>
                    <p>
                        Vi har skickat en bekräftelse med dina fullständiga försäkringsvillkor
                        till {sessionStorage.mail || "din emailaddress"}.
                    </p>
                    <a className={styles.button} href={sessionStorage.referral || "https://www.blocket.se/"}>Återvänd till Blocket</a>
                </div>
            </div>
            <div className={styles.footer} >
                <Footer />
            </div>
        </div>
    )
}

export default Success;