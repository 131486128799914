import React from "react";
import thumbs from "../../Utils/Images/failure.svg";
import Footer from "../Footer/Footer";

function Failure() {
    return (
        <div className="velosophy-failureWrapper">
            <div className="failure">
                <div>
                    <img className="thumbs" src={thumbs} alt={''} />
                </div>
                <h1>
                    Oops, något gick fel.
                </h1>
                <p>
                    Prova gärna igen, och dubbelkolla att
                    du valde rätt konto och att det finns täckning.
                </p>
                <p>
                    Om felet kvarstår kontakta Omocom via <a className="mail" href="mailto:hello@omocom.insurance">hello@omocom.insurance</a> eller 08-520 278 70.
                </p>
                <a className="button" href={window.location.origin + "/velosophy/se"} >Återvänd till Velosophy</a>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    )
}

export default Failure;
