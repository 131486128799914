import React from "react";
import styles from "./Error.module.css";
import thumbs from "../../Utils/Images/failure.svg";
import Footer from "../Footer/Footer";

function Error() {
    return (
        <div className={styles.errorWrapper}>
            <div className={styles.error}>
                <div>
                    <img className={styles.thumbs} src={thumbs} alt={''} />
                </div>
                <p>
                    Denna sida kan bara användas när du klickat en länk i en Blocketannons
                </p>
                <p>
                    Om du har några frågor kontakta Omocom via <a href="mailto:hello@omocom.insurance">hello@omocom.insurance</a> eller 08-520 278 70.
                </p>
            </div>
            <span className={styles.footer}>
                <Footer />
            </span>
        </div>
    )
}

export default Error;