import React from "react";
import styles from "./BlocketHeader.module.css";
import Logo from "../../Utils/Images/omcom_blocket-white.svg";

function BlocketHeader() {
    return (
        <div className={styles.blocketHeader} >
          <div className={styles.blocketHeaderLogo} >
            <img className={styles.blocketHeaderImg} alt={'Logo'} src={Logo} />
          </div>
        </div>
    )
}

export default BlocketHeader;