import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { useIntl } from "react-intl";

interface IButtonProps {
	values: any;
	onChange: Function;
	done: boolean;
	fieldName: string;
	isRequired?: boolean;
	setError: Function;
	displayErrors?: boolean;
}

const ButtonComponent: React.FC<IButtonProps> = (props) => {
	/** Initialization */
	const {
		values,
		onChange,
		done,
		fieldName,
		isRequired,
		setError,
		displayErrors
	} = props;

	const intl = useIntl();

	const onButtonPress = (e: any) => {
		setError("", fieldName);
		buttonStyling(e);

		let value = e.currentTarget.value;

		onChange(value, fieldName);
	};

	const buttonStyling = (e: any) => {
		let siblings = e.currentTarget.parentElement.children;
		for (let i = 0; i < siblings.length; i++) {
			if (siblings[i].classList.contains("active"))
				siblings[i].classList.remove("active")
		}
		e.currentTarget.classList.add("active");
	}

	const isSelected = () => {
		let buttons = document.getElementsByName(fieldName);
		let active = false;
        buttons.forEach(button => {
            if (button.classList.contains('active')) {
				active = true;
			}
        });

		return active;
	}

	useEffect(() => {
		isRequired && setError(intl.formatMessage({ id: "StoreForm.IsRequiredField" }), fieldName);
	// eslint-disable-next-line
	}, []);

	return (
		<div
			className="pt-3 pb-3 btn-group-toggle buttonState"
			data-toggle="buttons"
		>
			{values.map((value: any) => (
				<Button
					disabled={done}
					value={value.name}
					className="selection-button"
					name={fieldName}
					variant="contained"
					key={value.name}
					onClick={(
						e: React.MouseEvent<HTMLButtonElement, MouseEvent>
					) => {
						onButtonPress(e);
					}}
				>
					{value.name}
				</Button>
			))}
			{displayErrors && !isSelected() &&
				<p className="MuiFormHelperText-root">
					<span className="helperTextDisplayError">
						{intl.formatMessage({ id: "StoreForm.IsRequiredField" })}
					</span>
				</p>
			}
		</div>

	);
};

export default ButtonComponent;
