import React, { useEffect } from "react";
import { FormGroup, InputLabel } from "@material-ui/core";
import { useIntl } from "react-intl";
import ButtonComponent from "../CommonComponents/ButtonComponent";
import { PropertyMap } from "../../BackendApi/OmocomStoreApi";

interface IProps {
    value: any;
    setValue: Function;
    fieldName: string;
    setError: Function;
    displayErrors: boolean;
    done: boolean;
    map: PropertyMap;
    isRequired: boolean;
}

export const InsuranceInformation = (props: IProps) => {
    const { value, setValue, fieldName, setError, displayErrors, done, map, isRequired } = props;
    const intl = useIntl();

    const handleValue = (e: any, fieldName: string) => {
        let selected = value.find((values: any) => values.name === e);
        selected && setValue({amount: selected.premium.amount, currency: selected.premium.currency}, fieldName, undefined, map );
    }

    useEffect(() => {
        if (value.length === 1) {
            setValue({ amount: value[0].premium?.amount, currency: value[0].premium?.currency }, fieldName);
        }
        // eslint-disable-next-line
    }, [])

    if (value.length < 2 ) {
        return <></>
    }

    return (
        <FormGroup id="formControlsCondition" className="selection-buttons-wrapper">
            <InputLabel>
                {intl.formatMessage({
                    id: "StoreForm.insurancePackage",
                }) + (isRequired ? " *" : "")}
            </InputLabel>
            <ButtonComponent
                values={value}
                onChange={handleValue}
                done={done}
                fieldName={fieldName}
                isRequired={isRequired}
		        setError={setError}
                displayErrors={displayErrors}
            />
        </FormGroup>
    )
}