import React, { useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { TextField,	OutlinedInputProps,	InputLabel } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStylesTextField, useStylesTextArea,	helperTextStyles } from "../styles/styledComponents/stylesForComponents";
import { FormValidation } from "../../Utils/validation/validation";
import { ComponentType, PropertyMap } from "../../BackendApi/OmocomStoreApi";

export type OnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => void;
export type OnInputDateChange = (date: string, dateStr: string) => void;

interface IProps {
	/** The id of the form control */
	id: string;

	/** Label text for the control */
	label?: string;

	/** Help text to describe what the user should enter */
	help?: string;

	/** Placeholder text for when the field is empty */
	placeholder: string;

	autocomplete?: string;

	/** True if the field is required, false otherwise */
	isRequired: boolean;

	/** True if the field is disabled, false otherwise */
	disabled?: boolean;

	/** The type of field to use */
	fieldType: ComponentType | string;

	/** Indicates if the field is multiLine text field */
	multiLineTextBox?: boolean;

	/** Input value */
	value: string | number | undefined;

	/** Function that is called when the value is changed */
	onChange: Function;

	/** Boolean that checks if the form has been posted */
	done: boolean;

	/** Enum that checks where the value should be saved */
	map?: PropertyMap;

	/** Sets the error if there is one */
	setError?: Function;

	/** Indicates whether the errors should be displayed or not */
	displayErrors: boolean;
}

export const FieldGroupString: React.FC<IProps> = (props) => {
	let classes = useStylesTextField();
	const textAreaClass = useStylesTextArea();
	const helperTestClasses = helperTextStyles();
	const intl = useIntl();
	const {
		autocomplete,
		label,
		id,
		fieldType,
		disabled,
		isRequired,
		placeholder,
		multiLineTextBox,
		help,
		value,
		onChange,
		done,
		map,
		setError,
		displayErrors
	} = props;

	const handleOnChange = (e: any) => {
		setError && setError(errors(e.currentTarget.value), id);

		if (fieldType === "number" || 
			fieldType === ComponentType.MonetaryValue) {
				// disallows more than 2 digits after a dot
				if (e.currentTarget.value.toString().slice(-1) !== ".") {
					e = Math.round(parseFloat(e.currentTarget.value) * 100) / 100;
				}
		}

		if (e.currentTarget) {
			e = e.currentTarget.value;
		}

		onChange(e, id, fieldType, map);
	}

	if (multiLineTextBox) {
		classes = textAreaClass;
	}

	const errors = (newValue?: string) => {
		let currentValue = newValue !== undefined ? newValue : value;
		switch (fieldType) {
			case "email":
				return !FormValidation().isValidEmail(currentValue?.toString()) &&
					intl.formatMessage({ id: "StoreForm.validateFieldEmail" });
			case "number" || ComponentType.MonetaryValue:
				return !FormValidation().isValidObjectPriceAmount(currentValue?.toString()) &&
					intl.formatMessage({ id: "StoreForm.validateFieldCurrency" });
			default:
				return isRequired && !FormValidation().isValidText(currentValue, id) &&
					intl.formatMessage({ id: "StoreForm.showErrMessageForFieldText" });
		}
	}

	const helperText = () => {
		if (multiLineTextBox && !displayErrors) {
			return (
				<span className="helperTextStyle">
					{intl.formatMessage({
						id: "StoreForm.validateFieldLength",
					})}
				</span>
			);
		} else if (value) {
			return displayErrors ? (
				<span className="helperTextDisplayError">
					{errors()}
				</span>
			) : (
				<span />
			);
		} else if (!value && isRequired && displayErrors) {
			return (
				<span className="helperTextDisplayError">
					{intl.formatMessage({
						id: "StoreForm.validateFieldRequired",
					})}
				</span>
			);
		}

		return <span className="helperTextDefaultStyle" />;
	};

	const showError = () => {
		if (value && displayErrors && errors()) {
			return true;
		}
		return false;
	};

	const maxLength = multiLineTextBox ? 1000 : 200;

	useEffect(() => {
		isRequired && id !== 'firstName' && id !== 'lastName' && setError && setError(intl.formatMessage({ id: "StoreForm.showErrMessageForFieldText" }), id);
	// eslint-disable-next-line
	}, []);

	return (
		<div id={id} className={displayErrors ? "was-validated" : ""}>
			<InputLabel>{label ?? ""}</InputLabel>
			<TextField
				multiline={multiLineTextBox}
				inputProps={{ maxLength }}
				rows={5}
				error={showError()}
				helperText={helperText()}
				type={fieldType}
				autoComplete={autocomplete || "off"}
				disabled={disabled || done}
				onChange={handleOnChange}
				required={isRequired}
				placeholder={placeholder}
				value={value === null ? "" : value}
				fullWidth
				variant="outlined"
				FormHelperTextProps={{
					classes: helperTestClasses,
				}}
				className={helperTestClasses.contained}
				InputProps={
					{
						classes
					} as Partial<OutlinedInputProps>
				}
			/>
			{help && <Alert variant="warning">{help}</Alert>}
		</div>
	);
};
