import React, { memo } from "react";
import Modal from "react-bootstrap4-modal";
import "./styles/css/Modal.css";

interface IProps {
	onCloseModal: () => void;
	header: string;
	show: boolean;
	text: string;
	textIssueKey: string;
	info: [id: string, product: string];
	body: string;
	footer: string;
	footerLinkText: string;
	footerLink: string;
	logoResponse: any;
}

const storeModal = ({
	show,
	onCloseModal,
	header,
	text,
	info,
	body,
	footer,
	footerLinkText,
	footerLink,
	logoResponse,
}: IProps) => {
	const setTextInfo = () => {
		let textInfo = [] as any;
		info && info.forEach((product: any) => {
			textInfo.push(
				<div className="modal-product-wrapper" key={product.id}>
					<div>Insurance Id: {product.id}</div>
					<div>Product Name: {product.product}</div>
				</div>
			);
		})
		return (
			<div>
				{textInfo}
			</div>
		)
	}

	return (
	<>
		<Modal
			dialogClassName="modal-dialog modal-dialog-vertical"
			visible={show}
			className="fixed-right"
			onClickBackdrop={onCloseModal}
		>
			<div className="modal-logo">{logoResponse}</div>
			<div className="modal-header">
				<h3>{header}</h3>
			</div>
			<div className="modal-body" style={{ wordBreak: "break-word" }}>
				<p>
					{body ?? text}
				</p>
				<div>
					{setTextInfo()}
				</div>
				<p>
					{footer}
				</p>
				<p>
					{footerLinkText}
					<span>
						<a href={footerLink}>{footerLink}</a>
					</span>
				</p>
			</div>
		</Modal>
	</>
)};

export default memo(storeModal);
