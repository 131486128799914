import styles from "./TitleImg.module.css";
import defaultBackground from "../../Utils/Images/default-image.png";
import React, { useEffect } from "react";

function TitleImg() {
    useEffect(() => {
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);
        
        var image = parsed.adThumbnailUrl;
        var container = document.getElementById("title-img");
    
        if (container && image) {
            image = image.replace('/thumb/', '/images/');
            container.style.backgroundImage=`url(${image})`
        }
        else if (container && !image) {
            container.style.backgroundImage=`url(${defaultBackground})`
        }
    }, [])

    return (
        <div className={styles.titleImgWrapper} >
            <div id="title-img" className={styles.titleImg} />
        </div>
    )
}

export default TitleImg;