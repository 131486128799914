import React from "react";
import { Logo } from "./styles/styledComponents/styled";
import { Header } from "./Header";
import { useIntl } from "react-intl";
import authentication from "../B2c";

const StoreHeader = (props) => {
	const intl = useIntl();

	return (
		<>
			<div>
				<Logo />
			</div>
			<Header
				HeaderTitle={intl.formatMessage({
					id: "StoreForm.FormHeader",
				})}
				Ingress={intl.formatMessage({
					id: "StoreForm.FormHeaderIngress",
				})}
			/>
			<div style={{display: "none"}}>
				<input value="locale" type="button" onClick={() => { props.setLocale() }} />
				<input value="logout" type="button" onClick={() => { authentication.signOut() }} />
			</div>
		</>
	);
};

export default StoreHeader;
