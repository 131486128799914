import { localeLanguage } from "../translations/locales";

export interface IFieldProps {
	platformId: string;
	jiraIssueKey: string;
}

export interface IModal {
	// Modal popup when you submit the form and receive the response.
	submitting: boolean;
	logoResponse: any;
	modalHeader: string;
	modalText: string;
	modalInfoText: [id: string, product: string];
	modalResponseIssueKey: string;
	modalResponseBody: string;
	modalResponseFooter: string;
	modalResponseFooterLinkText: string;
	modalResponseFooterLink: string;
	showModal: boolean;
}

export const initialValues = {
	firstName: "",
	lastName: "",
	emailAddress: "",
	phoneNumber: "",
	userCultureInfo: localeLanguage,
	purchasedProducts: []
}

export const initialProductValues = [{
	"platformId": "",
	"marketName": "",
	"productName": "",
	"omocomCounterpartHandle": "",
	"productFields": [],
	"addOns": [],
	"shortDescription": "",
	"insuredAmount": {
		"amount": "",
		"currency": ""
	}
}]

export const buttonInsuranceTypes = ["Small", "Medium", "Large"];