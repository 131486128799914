import React from "react";

export default function Text() {
    return (
        <div className="text-wrapper">
            <div className="header-wrapper">
                <h1 className="header">Försäkra din Vélosophy med Omocom, endast 185kr per år</h1>
                <div className="price-info">
                    Endast <span>185kr</span>för ett helt år
                </div>
            </div>
            <p>
                Omocom har i samarbete med Vélosophy tagit fram en enkel och prisvärd 
                försäkring för att skydda din Vélosophy. Försäkringen är en 
                självriskeliminering vilket innebär att du använder din egen hemförsäkring, 
                där Omocom ersätter kostnad för självrisk. 
            </p>
            <p>
                Fyll i formuläret nedan och betala enkelt och tryggt med Trustly för att 
                aktivera försäkringen. Efter utförd betalning skickar Omocom ut ditt 
                personliga försäkringsbrev inklusive försäkringsvillkor till den e-postadress 
                du anger i formuläret nedan.
            </p>
        </div>
    )
}