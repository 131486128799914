import React from "react";
import arrow from "../../Utils/Images/arrow.svg";
import villkor from "../../Utils/Images/Villkor-velo.pdf";
import faktablad from "../../Utils/Images/Produktfaktablad-velo.pdf";

export default function Information(props: { isPhone: boolean; }) {
    return (
        <div className="information-wrapper">
            {!props.isPhone ?
                <>
                    <div className="information-inner">
                        <div className="information">
                            <h1 className="information-title">Stöld</h1>
                            <p className="information-text">
                                Om din cykel blir stulen när den är låst med ett SFF godkänt lås
                            </p>
                            <p className="information-text">
                                Försäkringen gäller för stöld som inträffar i hela Norden
                            </p>
                        </div>
                        <div className="information">
                            <h1 className="information-title">Skador</h1>
                            <p className="information-text">
                                Om din cykel blir skadad och behöver repareras eller ersättas med en reservdel
                            </p>
                        </div>
                        <div className="information">
                            <h1 className="information-title">0:- självrisk</h1>
                            <p className="information-text">
                                Försäkringen lämnar ersättning för den självrisk som dragits av genom
                                din ordinarie försäkring med upp till 10 000kr.
                            </p>
                        </div>
                        <div className="information last">
                            <h1 className="information-title">Det finstilta:</h1>
                            <p className="information-text">
                                <a href={villkor} target="_blank" rel="noreferrer"> Fullständiga försäkringsvillkor <img className="arrow" alt="" src={arrow} /></a>
                                <a href={faktablad} target="_blank" rel="noreferrer"> Produktfaktablad <img className="arrow" alt="" src={arrow} /></a>
                            </p>
                            <p className="information-text">Besök <a href="https://velosophy.cc/" target="_blank" rel="noreferrer" className="styled">Vélosophys hemsida <img className="arrow" alt="" src={arrow} /></a></p>
                        </div>
                    </div>
                    <div className="information-extra">
                        <h1 className="information-title">Visste du att...?</h1>
                        <p className="information-text">
                            Cykelstöld är ett av de vanligaste egendomsbrotten i Sverige.
                        </p>
                    </div>
                </>
                :
                <>
                    <div className="information-inner">
                        <div className="information">
                            <h1 className="information-title">Skyddar mot stöld och skador. Självrisk 0:-</h1>
                        </div>
                        <div className="information last">
                            <a className="information-title" href={villkor} target="_blank" rel="noreferrer"> Försäkringsvillkor <img className="arrow" alt="" src={arrow} /></a>
                            <a className="information-title" href={faktablad} target="_blank" rel="noreferrer"> Produktfaktablad <img className="arrow" alt="" src={arrow} /></a>
                        </div>
                    </div>
                    <div className="information-inner small">
                        <p className="information-text">
                            Försäkringen är en självriskeliminering. Observera att du behöver ha en ordinarie försäkring, t.ex. hemförsäkring,
                            som omfattar cykel för att självriskelimineringen ska gälla.
                        </p>
                    </div>
                </>
            }
        </div>
    )
}