import React from "react";
import styles from "./BlocketButton.module.css";

interface BlocketButtonProps {
    loading: boolean,
    value: string,
    onClick: any
}

export default function BlocketButton(props: BlocketButtonProps) {
    return (
        <input className={props.loading ? `${styles.button} ${styles.loading}` :
            `${styles.button}`} type="button" value={props.value} disabled={props.loading}
            onClick={props.onClick} />
    )
}