import Blocket from "./Blocket/Blocket";
import Error from "./Blocket/Error/Error";
import BlocketSuccess from "./Blocket/EndScreens/Success";
import BlocketFailure from "./Blocket/EndScreens/Failure";
import StoreLayout from "./Store/Layout";
import Velosophy from "./Velosophy/Velosophy";
import VelosophySuccess from "./Velosophy/EndScreens/Success";
import VelosophyFailure from "./Velosophy/EndScreens/Failure";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import authentication from './B2c';

authentication.initialize();

function OmocomRouter() {
	return (
		<Router>
			<App />
		</Router>
	);
}

function App() {
    const queryString = require('query-string');
    const parsed = queryString.parse(window.location.search);
    const QSExists = (parsed.adTitle && parsed.listId)

    return (
        <Switch>
            <Route exact path="/blocket/se">
                { QSExists ? 
                <Blocket />
                : <Error /> }
            </Route>
            <Route exact path="/blocket/success">
                <BlocketSuccess />
            </Route>
            <Route exact path="/blocket/failure">
                <BlocketFailure />
            </Route>
            <Route exact path="/velosophy/se">
                <Velosophy />
            </Route>
            <Route exact path="/velosophy/success">
                <VelosophySuccess />
            </Route>
            <Route exact path="/velosophy/failure">
                <VelosophyFailure />
            </Route>
            <Route exact path="/store/se" component={authentication.required(StoreLayout)} />
        </Switch>
    )
}

export default OmocomRouter;