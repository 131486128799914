import {Guid} from "guid-typescript";
import { addNotification } from "../Utils";

/**
 * A set of validation methods that can be used to validate different types of values.
 */
const FormValidation = () => {
	return {
		/**
		 * Validates that the given value is a valid phone number
		 *
		 * @param phoneNr The email address to validate
		 * @returns True if the given email is a valid address, false otherwise
		 */
		isValidPhoneNr: (phoneNr: string) => {
			const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
			return re.test(phoneNr);
		},

		/**
		 * Validates that the given value is a valid email address
		 *
		 * @param email The email address to validate
		 * @returns True if the given email is a valid address, false otherwise
		 */
		isValidEmail: (email: string | undefined): boolean => {
			if (!email) {
				return false;
			}
			const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return emailRex.test(email);
		},

		/**
		 * Validates that the given value is a valid currency string
		 *
		 * @param value The value to validate
		 * @returns True if the value is a valid currency value, false otherwise
		 * ToDo: Lock down the list to explicit, well known currencies according to ISO standard
		 */
		isValidObjectPriceAmount: (value: string | undefined): boolean => {
			if (!value) {
				return false;
			}
			const currencyRex = /^(\d+(?:,\d{1,2})?).*/;
			return currencyRex.test(value);
		},

		isValidGuid: (value: string | undefined): boolean => {
			if (!value) {
				return false;
			}
			else if (value === "00000000-0000-0000-0000-000000000000") {
				return true;
			}
			const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
			return regex.test(value) && Guid.isGuid(value);
		},

		isValidText: (value: string | number | undefined, id: string): boolean => {
			if (!value) {
				return false;
			}
			else if (typeof(value) === 'number' || id === "firstName" || id === "lastName") {
				return true;
			}
			
			const minLength = value?.length > 2;
			return minLength;
		},
	};
};

const validateFields = (errors: any, intl: any) => {
	if (Object.entries(errors).length === 0) {
		addNotification(intl.formatMessage({ id: "StoreForm.IsEmptyItemFields" }), intl.formatMessage({ id: "StoreForm.Error" }), "danger");

		return false;
	}
    for (const [, value] of Object.entries(errors)) {
        if (value) {
            addNotification(value, intl.formatMessage({ id: "StoreForm.Error" }), "danger");

            return false;
        }
    };

    return true;
}

export { FormValidation, validateFields };
