import React from "react";

export default function Footer() {
    return (
        <div className="footer">
            <a href="tel:+46 (0) 8 520 278 70" >+46 (0) 8 520 278 70</a> |
            <a href="mailto:hello@omocom.insurance">hello@omocom.insurance</a> |
            <a href="https://www.omocom.insurance">www.omocom.insurance</a>
        </div>
    )
}