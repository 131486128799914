import React, { memo } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

const HeaderContainer = styled.div`
	margin-bottom: 2rem;
`;

const HeaderBody = styled.div`
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
`;

const HeaderTitle = styled.h1`
	margin-bottom: 0;
	@media (max-width: 1040px) {
		text-align: center;
		margin-bottom: 1rem;
	}
`;

const Ingress = styled.div`
	margin-bottom: 0;
`;

const Alert = styled.div`
	color: red;
	margin-top: 2rem;
`

export interface IProps {
	HeaderTitle: string;
	Ingress?: string;
	Alert?: string;
}

export const Header = memo((props: IProps) => (
	<HeaderContainer>
		<HeaderBody>
			<Row className="align-items-center">
				<Col>
					<HeaderTitle>{props.HeaderTitle}</HeaderTitle>
					<Ingress>{props.Ingress}</Ingress>
					{ props.Alert && <Alert>{props.Alert}</Alert> }
				</Col>
			</Row>
		</HeaderBody>
	</HeaderContainer>
));
Header.displayName = "Header";
