import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import { FieldGroupString } from "../CommonComponents/FieldGroupString";

interface IUserInputInformationProps {
	form: any;
	setValue: Function;
	done: boolean;
	setError: Function;
	displayErrors: boolean;
}

const UserInputInformation: React.FC<IUserInputInformationProps> = (
	props: IUserInputInformationProps
) => {
	const { form, setValue, done, setError, displayErrors } = props;
	const intl = useIntl();

	useEffect(() => {
		const queryString = require('query-string');
		const parsed = queryString.parse(window.location.search);

		Object.keys(parsed).length !== 0 &&
			Object.entries(parsed).forEach(([queryKey, queryValue]) => {
				if (Object.keys(form).find((formKey) => formKey === queryKey)) {
					setValue(queryValue, queryKey);
					setError(false, queryKey);
				}
			});
		// eslint-disable-next-line
	}, [])

	return (
		<Grid container spacing={3}>
			<Grid item md={7} xs={12}>
				{/* <FieldGroupString
					value={form.ssn.ssn}
					onChange={setValue}
					done={done}
					id="ssn"
					fieldType="text"
					autocomplete="organization"
					label={intl.formatMessage({
						id: "StoreForm.personalNumberLabel",
					})}
					placeholder={intl.formatMessage({
						id: "StoreForm.personalNumberPlaceholder",
					})}
					isRequired={false}
					setError={setError}
					displayErrors={displayErrors}
				/> */}
				<FieldGroupString
					value={form.firstName}
					onChange={setValue}
					done={done}
					id="firstName"
					fieldType="text"
					autocomplete="given-name"
					label={intl.formatMessage({
						id: "StoreForm.firstNameLabel",
					}) + ' *'}
					placeholder={intl.formatMessage({
						id: "StoreForm.firstNameLabel",
					})}
					isRequired
					setError={setError}
					displayErrors={displayErrors}
				/>
				<FieldGroupString
					value={form.lastName}
					onChange={setValue}
					done={done}
					id="lastName"
					fieldType="text"
					autocomplete="family-name"
					label={intl.formatMessage({
						id: "StoreForm.lastNameLabel",
					})  + ' *'}
					placeholder={intl.formatMessage({
						id: "StoreForm.lastNameLabel",
					})}
					isRequired
					setError={setError}
					displayErrors={displayErrors}
				/>
				<FieldGroupString
					value={form.emailAddress}
					onChange={setValue}
					done={done}
					id="emailAddress"
					fieldType="email"
					autocomplete="email"
					label={intl.formatMessage({
						id: "StoreForm.emailAddressLabel",
					}) + ' *'}
					placeholder={"XXXX@XXXXXXXXX.XX"}
					isRequired
					setError={setError}
					displayErrors={displayErrors}
				/>
				<FieldGroupString
					value={form.phoneNumber}
					onChange={setValue}
					done={done}
					id="phoneNumber"
					autocomplete="tel"
					fieldType="phone"
					label={intl.formatMessage({
						id: "StoreForm.phoneNumberLabel",
					})}
					isRequired={false}
					placeholder={"123-456-78-90"}
					setError={setError}
					displayErrors={displayErrors}
				/>
			</Grid>
		</Grid>
	);
};

export default UserInputInformation;