import React from "react";
import styles from "./Title.module.css";

export default function Title() {
    return (
    <div className={styles.titleWrapper}>
        <h1 className={styles.title}>Köp dolda fel-försäkringen</h1>
        <div className={styles.text}>
            Grattis till ditt Blocket-fynd! Här kan du köpa en försäkring som skyddar
            din produkt mot dolda fel - i upp till 14 dagar!
        </div>
    </div>
    )
}