import React from "react";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import { IProductInfoReq } from "../../BackendApi/OmocomStoreApi";

interface IBasket {
    products: IProductInfoReq[];
    removeItem: Function;
    done: Boolean;
}

const Basket = (props: IBasket) => {
    const { products, removeItem, done } = props;
    const setItems = () => {
        let tempItems = [] as any;
        products.forEach((product: IProductInfoReq, i: number) => {
            tempItems.push(<BasketItems product={product} removeItem={removeItem} index={i} key={"basket-" + i} done={done} />)
        });
        
        return tempItems;
    }

    return (
        <div className="basket-wrapper">
            {setItems()}
        </div>
    )
};

const BasketItems = (props: any) => {
    const addClass = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let classList = e.currentTarget?.parentElement?.parentElement?.classList || e.currentTarget.classList;
        classList.contains("basket-expand") ? classList.remove("basket-expand") : classList.add("basket-expand");
    }

    const setFields = () => {
        let tempFields = [] as any;

        props.product.InsuranceExternalProperties && tempFields.push(CombineBasketItems(props.product.InsuranceExternalProperties));
        props.product.InsurableObjectExternalProperties && tempFields.push(CombineBasketItems(props.product.InsurableObjectExternalProperties));

        if (props.product.insuredAmount) {
            tempFields.push(
                <div key={"fieldInsuredAmount"}>
                    <div className="basket-label">{"Insured Amount"}</div>
                    <div className="basket-value">{`${props.product.insuredAmount.amount} ${props.product.insuredAmount.currency}`}</div>
                </div>
            )
        }

        return tempFields;
    }

    return(
        <div className="basket">
            <div className="basket-header-wrapper">
                <div className="basket-header" onClick={e => addClass(e)}>
                    <span className="basket-expand"><RemoveIcon /></span>
                    <span className="basket-shrink"><AddIcon /></span>
                    <span className="header-value">{props.product.shortDescription || `Product: ${props.index + 1}`}</span>
                </div>
                {!props.done && <span className="basket-close" onClick={() => {props.removeItem(props.index)}}><CloseIcon /></span>}
            </div>
            <div className="basket-items" >
                {setFields()}
            </div>
        </div>
    )
}

const CombineBasketItems = (product) => {
    let tempFields = [] as JSX.Element[];
    product.forEach((field: any, i: number) => {
        let value = field.value;
        if (field.dateTimeValue) {
            value = field.dateTimeValue.format("MMMM Do YYYY");;
        } else if (field.monetaryValue) {
            value = `${field.monetaryValue.amount} ${field.monetaryValue.currency}`;
        }

        tempFields.push(
            <div key={"field" + i}>
                <div className="basket-label">{field.name}</div>
                <div className="basket-value">{value}</div>
            </div>
        );
    });
    
    return tempFields
}

export default Basket;
