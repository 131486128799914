import React from "react";
import Logo from "../../Utils/Logo";
import styles from "./Footer.module.css";

function Footer() {
    return (
        <div className={styles.footer}>
            <Logo />
            <div className={styles.contactInfo}>
                <span className={styles.footerMail}>
                    <a href="tel:+46 (0) 8 520 278 70" >+46 (0) 8 520 278 70</a> | <a href="mailto:hello@omocom.insurance">hello@omocom.insurance</a>
                </span>
                <div>
                    <a href="https://www.omocom.insurance">www.omocom.insurance</a>
                </div>
            </div>
        </div>
    )
}

export default Footer;