import * as React from "react";
import { Container } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import StoreContainer from "./StoreContainer";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const Layout = () => {
	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<ReactNotifications />
			<Container fluid>
				<Grid container className="justify-content-center">
					<Grid item lg={8}>
						<StoreContainer />
					</Grid>
				</Grid>
			</Container>
		</MuiPickersUtilsProvider>
	);
};

export default Layout;